<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-card>
      <b-row>
        <!-- sorting -->
        <b-col md="3" sm="6" class="mt-1">
          <b-form-group
            label="Sort"
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-25"
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- filter -->
        <b-col md="3" class="mt-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- add permission button -->
        <b-col md="2" class="my-1">
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              style="height: 2.142rem; line-height: 0.5;"
              v-if="authPermissions.includes('create')"
              variant="primary"
              :to="{ path: '/Add-Permission' }"
            >
              <span class="text-nowrap">Add permission</span>
            </b-button>
          </div>
        </b-col>
        <!-- start data table -->
        <b-col cols="12" class="mt-2">
          <b-table
            id="perTable"
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(actions)="items">
              <b-form class="auth-login-form" @submit.prevent>
                <!-- delete button -->
                <b-button
                  v-if="authPermissions.includes('delete')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  type="submit"
                  @click="deletePermissions(items.item.id)"
                >
                  Delete
                </b-button>
              </b-form>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <div>
          <!-- start pagination -->
          <b-pagination
            aria-controls="perTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {

  data() {
    return {
      authPermissions: [],
      showDismissibleAlert: false,
      errors_back: {
        username: 'test',
        username1: 'data',
      },
      errors_delete: {
        username: 'test',
        username1: 'data',
      },
      perPage: 25,
      pageOptions: [25, 50, 100],
      currentPage: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Permission',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      items: [],
    }
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.items.length
    },
  },
  mounted() {},
  methods: {
    fetchData() {
      // fetch data of permissions
      axios
        .get('permission/index')
        .then((result) => {
          this.items.length = 0
          this.items = result.data.data
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            this.errors_back = err.response.data.data
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'this is server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // delete function to delete permission
    deletePermissions(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete permission.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('permission/' + id + '/delete')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
